import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import styles from "./navigation-menu.module.scss";
import { StaticContext } from "../../context/static-context/static-context";
import useHover from "../../hooks/useHover";

interface NavigationMenu {
	position?: "header" | "footer" | "burger";
	isScrool?: boolean;
}

interface MenuItemProps {
	rel?: string;
	target?: string;
	title: string;
	to: string;
	isScrool: boolean;
	onClick?: () => void;
}

function NavigationMenu({
	position = "header",
	isScrool = false,
}: NavigationMenu): JSX.Element {
	const [statesOpen, setStatesOpen] = useState<boolean>(false);

	const [scrollPosition, setScrollPosition] = useState(0);
	const [classNameLink, setClassNameLink] = useState<string>(styles.itemHref);
	const [hoverAboutLink, isHoverAboutLink] = useHover<HTMLDivElement | any>();
	const [hoverAboutBox, isHoverAboutBox] = useHover<HTMLDivElement | any>();
	const [classNameLinkInventory, setClassNameLinkInventory] = useState<string>(
		styles.aboutLink,
	);
	const router = useRouter();

	useEffect(() => {
		if (router.asPath === "/") {
			setClassNameLinkInventory(styles.aboutLink);
		} else {
			setClassNameLinkInventory(styles.aboutLinkSecond);
		}
	}, [router.asPath]);

	useEffect(() => {
		if (router.asPath === "/") {
			setClassNameLinkInventory(styles.aboutLink);
		} else {
			setClassNameLinkInventory(styles.aboutLinkSecond);
		}

		if (isHoverAboutBox) {
			setClassNameLinkInventory(styles.aboutLink);
		}
	}, [router.asPath, statesOpen, isHoverAboutBox]);

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		isHoverAboutLink || isHoverAboutBox
			? setStatesOpen(false)
			: setStatesOpen(true);
	}, [isHoverAboutLink, isHoverAboutBox]);

	return (
		<>
			<div
				className={`
				${position === "burger" ? styles.burgerMenu : styles.navigationMenu}
				${position === "footer" ? styles.footerNavigationMenu : ""}
				${position === "burger" ? styles.burgerNavigationMenu : ""}
			`}
			>
				<div
					className={`${
						statesOpen
							? styles.boxDropDown
							: scrollPosition !== 0
							? styles.boxDropDownScrool
							: styles.boxDropDownactive
					}`}
					ref={hoverAboutLink}
				>
					<div style={{ position: "relative" }}>
						<div
							className={`${statesOpen ? classNameLink : styles.itemHref} ${
								scrollPosition !== 0 ? styles.itemHrefScrool : ""
							}`}
							onClick={() => setStatesOpen(!statesOpen)}
							ref={hoverAboutLink}
						>
							<div>
								<div className={classNameLinkInventory}>
									<Link href={"/inventory"}>Inventory</Link>
								</div>
							</div>
						</div>

						<div
							className={`${styles.mdModal} ${styles.mdEffect10} ${
								!statesOpen && scrollPosition !== 0 ? styles.mdShow : ""
							}`}
							ref={hoverAboutBox}
						>
							<div className={`${styles.mdContent}`}>
								<Link href="/inventory">
									<a onClick={() => setStatesOpen(!statesOpen)}>Inventory</a>
								</Link>
								<Link href="/pallet-warehouse">
									<a onClick={() => setStatesOpen(!statesOpen)}>
										Pallets
									</a>
								</Link>
								<Link href="/">
									<a onClick={() => setStatesOpen(!statesOpen)}>
									</a>
								</Link>
							</div>
						</div>

						<div className={styles.mdOverlay}></div>
					</div>

					<div
						className={`${
							statesOpen
								? styles.noActive
								: scrollPosition !== 0
								? styles.activeScrool
								: styles.activeDark
						}`}
						ref={hoverAboutBox}
					>
						<Link href="/inventory">
							<a
								onClick={() => setStatesOpen(!statesOpen)}
								className={`${styles.modalCloseItem} ${
									scrollPosition !== 0 ? styles.modalCloseItemisScrool : ""
								}`}
							>
								Truckloads
							</a>
						</Link>
						<Link href="/pallet-warehouse">
							<a
								onClick={() => setStatesOpen(!statesOpen)}
								className={`${styles.modalCloseItem} ${
									scrollPosition !== 0 ? styles.modalCloseItemisScrool : ""
								}`}
							>
								Pallets
							</a>
						</Link>

						{/* <Link href="https://pallets.buylowwarehouse.com">
							<a
								target="_blank"
								onClick={() => setStatesOpen(!statesOpen)}
								className={`${styles.modalCloseItem} ${
									scrollPosition !== 0 ? styles.modalCloseItemisScrool : ""
								}`}
							>
								Pallets
							</a>
						</Link>
						<Link href="/inventory/state">
							<a
								onClick={() => setStatesOpen(!statesOpen)}
								className={`${styles.modalCloseItem} ${
									scrollPosition !== 0 ? styles.modalCloseItemisScrool : ""
								}`}
							>
								States
							</a>
						</Link>
						<Link href="/inventory/category">
							<a
								onClick={() => setStatesOpen(!statesOpen)}
								className={`${styles.modalCloseItem} ${
									scrollPosition !== 0 ? styles.modalCloseItemisScrool : ""
								}`}
							>
								Categories
							</a>
						</Link> */}
					</div>
				</div>
				<NavigationMenuItem isScrool={isScrool} title="About" to="/about" />
				<NavigationMenuItem
					isScrool={isScrool}
					title="Resources"
					to="/resources"
				/>
				<NavigationMenuItem isScrool={isScrool} title="FAQ" to="/faq" />
				<NavigationMenuItem
					isScrool={isScrool}
					title="State of the Industry Report"
					to="/liquidationindustryreport"
				/>
				<NavigationMenuItem isScrool={isScrool} title="Reviews" to="/reviews" />
				<NavigationMenuItem isScrool={isScrool} title="Contact" to="/contact" />
			</div>
		</>
	);
}

function NavigationMenuItem({
	to,
	title,
	target,
	rel,
	isScrool,
	onClick,
}: MenuItemProps): JSX.Element {
	const router = useRouter();
	const [className, setClassName] = useState<string>(styles.link);

	useEffect(() => {
		if (router.asPath === "/") {
			setClassName(styles.linkSecond);
		} else {
			setClassName(styles.link);
		}
	}, [router.asPath]);

	return (
		<Link href={to}>
			<a
				className={`${className} ${
					router.pathname === to ? styles.active : ""
				} ${isScrool ? styles.isScrool : ""} ${
					to === "/liquidationindustryreport" ? styles.itemBurgerState : ""
				} `}
				rel={rel}
				target={target}
				href={to}
				onClick={onClick}
			>
				{title}
			</a>
		</Link>
	);
}

export default NavigationMenu;
